.epodConfig .text-center {
  text-align: center;
}
.epodConfig .mar-left-15 {
  margin-left: 15px;
}
.epodConfig .epod-left {
  text-align: right;
  color: #444;
}
.epodConfig .callRequest {
  border: 1px solid #ccc;
  width: 40%;
  padding: 10px;
  margin-left: 10px;
}