/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

.ant-btn {
  margin-right: 8px;
}

.ant-table table {
  background: #fff;
}

.ant-layout-header {
  padding: 0 15px;
}

.ant-layout-sider {
  background: #fff;
}

.ant-table-wrapper {
  padding: 20px;
}

.subHeaderLabel {
  line-height: 2;
}

.brand {
  width: 48px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
}

.headerTitle {
  color: #fff;
  display: inline-block;
  margin-left: 20px;
}

li.ant-menu-item:first-child {
  margin-top: 20px;
}

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  word-break: break-all;
}

.ht_clone_top.handsontable {
  width: auto !important;
}

.wtHolder {
  width: auto !important;
}