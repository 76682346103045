.autoclosure {
    width: 95%;
    margin: 20px auto;
    padding: 30px 70px;
    border: 1px solid #7e7b7b;
    .ctaMain {
      display: flex;
      margin: auto;
      padding-top: 30px;
      .cta {
        height: 40px;
        padding: 0 30px;
      }
    }
  }

  .filter {
    width: auto;
    margin: 5px;
    padding: 10px;
    border: 1px solid #7e7b7b;
    .ctaMain {
      display: flex;
      margin: auto;
      padding-top: 10;
      .cta {
        height: auto;
        padding: 0 10;
      }
    }
  }