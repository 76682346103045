.dashbaordConfigForm {
    width: 95%;
    margin: 20px auto;
    padding: 30px 70px;
    border: 1px solid #7e7b7b;
    .ctaMain {
      display: flex;
      margin: auto;
      padding-top: 30px;
      .cta {
        height: 40px;
        padding: 0 30px;
      }
    }
  }

  .btn-layout{
    padding-top:40px;
    margin-top:3px 
  }
  