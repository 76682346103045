.rounded-list ol {
  counter-increment: li; /* Initiate a counter */
  list-style: none; /* Remove default numbering */
  *list-style: decimal; /* Keep using default numbering for IE6/7 */
  font: 15px 'trebuchet MS', 'lucida sans';
  padding: 0;
  margin-bottom: 4em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.rounded-list ol ol {
  margin: 0 0 0 2em; /* Add some left margin for inner lists */
}
.rounded-list li {
  position: relative;
  display: block;
  padding: 0.4em 0.4em 0.4em 2em;
  *padding: 0.4em;
  margin: 0.5em 0;
  background: #ddd;
  color: #444;
  text-decoration: none;
  border-radius: 0.3em;
  transition: all 0.3s ease-out;
}

.rounded-list li:hover {
  background: #eee;
}

.rounded-list li:hover:before {
  transform: rotate(360deg);
}

.rounded-list li:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f1ea';
  counter-increment: li;
  position: absolute;
  left: -1.3em;
  top: 50%;
  margin-top: -1em;
  background: #fdbe0c;
  height: 2em;
  width: 2em;
  border: 0.3em solid #fff;
  text-align: center;
  font-weight: bold;
  border-radius: 1.5em;
  transition: all 0.3s ease-out;
}
.textArea {
  outline: 0;
  height: auto;
  max-width: 600px;
  font-weight: 400;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  line-height: 2em;
  border: none;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  -webkit-transition: height 2s ease;
  -moz-transition: height 2s ease;
  -ms-transition: height 2s ease;
  -o-transition: height 2s ease;
  transition: height 2s ease;
}

.inputStyle {
  margin-bottom: 2px;
  outline: 0;
  max-width: 600px;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  -webkit-transition: height 2s ease;
  -moz-transition: height 2s ease;
  -ms-transition: height 2s ease;
  -o-transition: height 2s ease;
  transition: height 2s ease;
}
.mobileNumber {
  display: flex;
  background: #fff;
  padding: 5px;
}
.crossBtn {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #fdbe0c;
  border: #fdbe0c;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #444;
  font-weight: bold;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
