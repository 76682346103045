.sidebarForm {
    width: 95%;
    margin: 25px auto;
    border: 1px solid #7e7b7b;
    padding: 30px;
    text-align: center;
    .ctaMain {
      display: flex;
      margin: auto;
      padding-top: 30px;
      .cta {
        height: 40px;
        padding: 0 30px;
      }
    }
}


.headingMargin{
  margin-top: 15px auto;
}