#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.wayPointMain {
  padding: 8px 40px;
}

.dropdownWidth {
  width: 100%;
  padding: 8px;
}

.addWayPointContainer {
  text-align: right;
  padding-top: 8px;
}