@import '~antd/dist/antd.css';

.homepage {
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  height: 740px;
  margin: 0 auto;
}
.textHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 60px;
  font-size: 18px;
  margin: 10px auto;
  border: 2px solid #ccc;
  margin-bottom: 0;
}
.marbotom0 {
  margin-bottom: 0;
}

.dropdownwidth {
  width: 100%;
}
.seconddrop {
  margin-left: 20px;
}
.alignright {
  text-align: right;
}
.ant-table-thead > tr > th {
  color: #000;
  font-weight: bold;
  background: #ddd;
}
/* Add RoutePopUp css */
.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.handsontable table thead th {
  white-space: pre-line;
  padding: 13px 5px 0 5px;
  min-width: 1200; /* enter here your max header width */
}
.tooltip {
  position: relative;
}

.tooltip::before {
  content: attr(title);
  display: inline-block;
  position: absolute;
  top: 120%;
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: 0.3s;
  overflow: hidden;
  pointer-events: none; /* prevents tooltip from firing on pseudo hover */
}

.tooltip:hover::before {
  opacity: 1;
}

.ht_clone_top {
  overflow: visible !important;
}

.htCore th {
  overflow: visible !important;
}
.routeInput {
  height: 40px;
}
