.bulk-upload-container {
    padding: 1rem;
  
    & .bulk-upload-box {
      margin: 2rem;
    }
  }
  
  .ant-modal-mask {
    background-color: #ebebeb !important;
    opacity: 0.01;
  }
  
  .ant-modal-header {
    background-color: #ebebeb !important;
  }
  
  .pruned-modal-header-text{
    margin-bottom: 5px;
  }