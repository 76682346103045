.homepage {
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  height: 740px;
  margin: 0 auto;
}
.textHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 60px;
  font-size: 18px;
  margin: 10px auto;
  border: 2px solid #ccc;
  margin-bottom: 0;
}
.marbotom0 {
  margin-bottom: 0;
}

.dropdownwidth {
  width: 100%;
}
.seconddrop {
  margin-left: 20px;
}
.alignright {
  text-align: right;
}
.ant-table-thead > tr > th {
  color: #000;
  font-weight: bold;
  background: #ddd;
}
/* Add RoutePopUp css */
.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.handsontable table thead th {
  white-space: pre-line;
  padding: 13px 5px 0 5px;
  min-width: 1200; /* enter here your max header width */
}
.tooltip {
  position: relative;
}

.tooltip::before {
  content: attr(title);
  display: inline-block;
  position: absolute;
  top: 120%;
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: 0.3s;
  overflow: hidden;
  pointer-events: none; /* prevents tooltip from firing on pseudo hover */
}

.tooltip:hover::before {
  opacity: 1;
}

.ht_clone_top {
  overflow: visible !important;
}

.htCore th {
  overflow: visible !important;
}
.routeInput {
  height: 40px;
}

.geosuggest {
  position: relative;
  text-align: left;
}
.geosuggest__fixture:before {
  content: '\2690';
  display: block;
  width: 20px;
  height: 20px;
  float: left;
  margin: 0;
}
.geosuggest__input {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0.5em 1em;
  transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 115%;
  left: 0;
  right: 0;
  max-height: 15em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 4;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
     * A geosuggest item
     */
.geosuggest__item {
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item--active {
  background: #e6f7ff;
  color: #000;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.wayPointMain {
  padding: 8px 40px;
}

.dropdownWidth {
  width: 100%;
  padding: 8px;
}

.addWayPointContainer {
  text-align: right;
  padding-top: 8px;
}
.sidebarForm {
  width: 95%;
  margin: 25px auto;
  border: 1px solid #7e7b7b;
  padding: 30px;
  text-align: center;
}
.sidebarForm .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 30px;
}
.sidebarForm .ctaMain .cta {
  height: 40px;
  padding: 0 30px;
}

.headingMargin {
  margin-top: 15px auto;
}
.autoclosure {
  width: 95%;
  margin: 20px auto;
  padding: 30px 70px;
  border: 1px solid #7e7b7b;
}
.autoclosure .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 30px;
}
.autoclosure .ctaMain .cta {
  height: 40px;
  padding: 0 30px;
}

.filter {
  width: auto;
  margin: 5px;
  padding: 10px;
  border: 1px solid #7e7b7b;
}
.filter .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 10;
}
.filter .ctaMain .cta {
  height: auto;
  padding: 0 10;
}
.dashbaordConfigForm {
  width: 95%;
  margin: 20px auto;
  padding: 30px 70px;
  border: 1px solid #7e7b7b;
}
.dashbaordConfigForm .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 30px;
}
.dashbaordConfigForm .ctaMain .cta {
  height: 40px;
  padding: 0 30px;
}

.btn-layout {
  padding-top: 40px;
  margin-top: 3px;
}
.exceptionConfigForm {
  width: 95%;
  margin: 20px auto;
  padding: 30px 70px;
  border: 1px solid #7e7b7b;
}
.exceptionConfigForm .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 30px;
}
.exceptionConfigForm .ctaMain .cta {
  height: 40px;
  padding: 0 30px;
}

.filter {
  width: auto;
  margin: 5px;
  padding: 10px;
  border: 1px solid #7e7b7b;
}
.filter .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 10;
}
.filter .ctaMain .cta {
  height: auto;
  padding: 0 10;
}

.deleteButton {
  margin-top: 3px;
}
.rounded-list ol {
  counter-increment: li; /* Initiate a counter */
  list-style: none; /* Remove default numbering */
  *list-style: decimal; /* Keep using default numbering for IE6/7 */
  font: 15px 'trebuchet MS', 'lucida sans';
  padding: 0;
  margin-bottom: 4em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}
.rounded-list ol ol {
  margin: 0 0 0 2em; /* Add some left margin for inner lists */
}
.rounded-list li {
  position: relative;
  display: block;
  padding: 0.4em 0.4em 0.4em 2em;
  *padding: 0.4em;
  margin: 0.5em 0;
  background: #ddd;
  color: #444;
  text-decoration: none;
  border-radius: 0.3em;
  transition: all 0.3s ease-out;
}

.rounded-list li:hover {
  background: #eee;
}

.rounded-list li:hover:before {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.rounded-list li:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f1ea';
  counter-increment: li;
  position: absolute;
  left: -1.3em;
  top: 50%;
  margin-top: -1em;
  background: #fdbe0c;
  height: 2em;
  width: 2em;
  border: 0.3em solid #fff;
  text-align: center;
  font-weight: bold;
  border-radius: 1.5em;
  transition: all 0.3s ease-out;
}
.textArea {
  outline: 0;
  height: auto;
  max-width: 600px;
  font-weight: 400;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  line-height: 2em;
  border: none;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: height 2s ease;
}

.inputStyle {
  margin-bottom: 2px;
  outline: 0;
  max-width: 600px;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  border: none;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: height 2s ease;
}
.mobileNumber {
  display: flex;
  background: #fff;
  padding: 5px;
}
.crossBtn {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #fdbe0c;
  border: #fdbe0c;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #444;
  font-weight: bold;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}

.epodConfig .text-center {
  text-align: center;
}
.epodConfig .mar-left-15 {
  margin-left: 15px;
}
.epodConfig .epod-left {
  text-align: right;
  color: #444;
}
.epodConfig .callRequest {
  border: 1px solid #ccc;
  width: 40%;
  padding: 10px;
  margin-left: 10px;
}
.apConfigFormMain {
  width: 50%;
  margin: 25px auto;
  padding: 30px 70px;
  border: 1px solid #7e7b7b;
}
.apConfigFormMain .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 30px;
}
.apConfigFormMain .ctaMain .cta {
  height: 40px;
  padding: 0 30px;
}
.bulk-upload-container {
  padding: 1rem;
}
.bulk-upload-container .bulk-upload-box {
  margin: 2rem;
}

.ant-modal-mask {
  background-color: #ebebeb !important;
  opacity: 0.01;
}

.ant-modal-header {
  background-color: #ebebeb !important;
}

.pruned-modal-header-text {
  margin-bottom: 5px;
}
/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

.ant-btn {
  margin-right: 8px;
}

.ant-table table {
  background: #fff;
}

.ant-layout-header {
  padding: 0 15px;
}

.ant-layout-sider {
  background: #fff;
}

.ant-table-wrapper {
  padding: 20px;
}

.subHeaderLabel {
  line-height: 2;
}

.brand {
  width: 48px;
  height: 40px;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
}

.headerTitle {
  color: #fff;
  display: inline-block;
  margin-left: 20px;
}

li.ant-menu-item:first-child {
  margin-top: 20px;
}

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  word-break: break-all;
}

.ht_clone_top.handsontable {
  width: auto !important;
}

.wtHolder {
  width: auto !important;
}
