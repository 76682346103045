.autoclosure {
  width: 95%;
  margin: 20px auto;
  padding: 30px 70px;
  border: 1px solid #7e7b7b;
}
.autoclosure .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 30px;
}
.autoclosure .ctaMain .cta {
  height: 40px;
  padding: 0 30px;
}

.filter {
  width: auto;
  margin: 5px;
  padding: 10px;
  border: 1px solid #7e7b7b;
}
.filter .ctaMain {
  display: flex;
  margin: auto;
  padding-top: 10;
}
.filter .ctaMain .cta {
  height: auto;
  padding: 0 10;
}