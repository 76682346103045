.epodConfig {
  .text-center {
    text-align: center;
  }
  .mar-left-15 {
    margin-left: 15px;
  }
  .epod-left {
    text-align: right;
    color: #444;
  }
  .callRequest {
    border: 1px solid #ccc;
    width: 40%;
    padding: 10px;
    margin-left: 10px;
  }
}
